import React from "react"
import { Helmet } from "react-helmet"
import NavigationBar from "../components/Navbar"
import loadable from "@loadable/component"
const Footer = loadable(() => import("../components/footer3"))

export default function PrivacyPolicy() {
  return (
    <div className="mb-6">
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <meta charSet="utf-8" />
        <title>Terms and Conditions</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="description"
          content="This Terms and Conditions applies to our website. By using our website, you hereby consent to these Terms and Conditions."
        />
      </Helmet>
      <div className="sm:sticky top-0 z-50">
        <NavigationBar />
      </div>
      <div className="mt-6 container mx-auto px-5 md:px-7 lg:px-8 xl:px-10 pt-5 md:pt-8">
        <h1 className="tracking-wider text-2xl mb-4 xl:text-3xl text-gray-900 text-center">
          Terms and Conditions
        </h1>
        <p className="mb-4 mt-4 tracking-wider text-gray-600">
          Last updated on Aug 22nd 2023
        </p>

        <div className="tracking-wider text-sm xl:text-base 2xl:text-lg text-gray-600">
          <p className="mb-4">
            These Terms and Conditions outline the rules and regulations for the
            use of enjoyalgorithms.com ("Website") located at
            https://enjoyalgorithms.com, EnjoyAlgorithms ("Business Name") and
            Code Algorithms Private Limited ("Company Name"). By accessing this
            website, we assume you accept these terms and conditions. Do not
            continue to use enjoyalgorithms.com if you do not agree to abide by
            all the terms and conditions stated on this page.
          </p>
          <p className="mb-4">
            EnjoyAlgorithms reserves the right to change or revise the terms and
            conditions at any time by posting any changes on this Website.
            EnjoyAlgorithms will alert you to changes or revisions by indicating
            the date of the last revision at the top of this page. The changed
            or revised terms and conditions will be effective immediately after
            being posted on this Website.
          </p>
          <p className="mb-4">
            The following terminology applies to these Terms and Conditions,
            Privacy Policy, and all Agreements: "Client," "You," and "Your"
            refer to you, the person who uses this website and complies with the
            Company’s terms and conditions. "The Company," "Ourselves," "We,"
            "Our," and "Us" refer to our Company. "Party," "Parties," or "Us"
            refer to both the Client and ourselves. Any use of the above
            terminology or other words in the singular, plural, capitalization,
            and/or he/she or they is taken as interchangeable and therefore as
            referring to the same.
          </p>
        </div>

        <h2 className="text-xl 2xl:text-2xl tracking-wider mt-6 mb-4">
          Product, Content and Intellectual Property
        </h2>
        <div className="tracking-wider text-sm xl:text-base 2xl:text-lg text-gray-600">
          <p className="mb-4">
            This Website offers for sale certain products. By placing an order
            for Products through this Website, you agree to the terms set forth
            in these Terms and Conditions. EnjoyAlgorithms has proprietary
            rights and trade secrets in the Products. You must not copy,
            reproduce, resell or redistribute any Product manufactured and/or
            distributed by EnjoyAlgorithms.
          </p>
          <p className="mb-4">
            Unless otherwise stated, enjoyalgorithms.com and/or its licensors
            own the intellectual property rights for all content and material on
            enjoyalgorithms.com. All intellectual property rights are reserved. 
          </p>
          <p className="mb-4">
            You can access this from enjoyalgorithms.com for your own personal
            use subject to restrictions set in these terms and conditions. You
            must not republish, sell, rent, reproduce, duplicate or copy, resell
            or redistribute any content and material from enjoyalgorithms.com
          </p>
          <p className="mb-4">
            EnjoyAlgorithms also has rights to all trademarks and specific
            layouts of the webpages on this website, including design, images and other information.
          </p>
          <p className="mb-4">
            You hereby grant enjoyalgorithms.com a non-exclusive license to use,
            and edit any of your testimonials in any and all forms, formats or
            media.
          </p>
          <p className="mb-4">
            Any links to third-party websites are provided solely as a
            convenience to you. EnjoyAlgorithms is not responsible for the
            content of or any damage that may result from your access to or
            reliance on these third-party websites. If you link to third-party
            websites, you do so at your own risk.
          </p>
        </div>
        <h2 className="text-xl 2xl:text-2xl tracking-wider mt-6 mb-4">
          Use of Website
        </h2>
        <div className="tracking-wider text-sm xl:text-base 2xl:text-lg text-gray-600">
          <p className="mb-4">
            You must not use the Website for illegal purposes. You must (1)
            abide by all applicable local, state, national, and international
            laws and regulations in your use of the Website, including those
            related to intellectual property, (2) not interfere with or disrupt
            the use and enjoyment of the Website by other users, (3) not resell
            materials from the Website, (4) not engage, directly or indirectly,
            in the transmission of "spam," chain letters, junk mail, or any
            other type of unsolicited communication, and (5) not defame, harass,
            abuse, or disrupt other users of the Website.
          </p>
        </div>

        <h2 className="text-xl 2xl:text-2xl tracking-wider mt-6 mb-4">
          Posting
        </h2>
        <div className="tracking-wider text-sm xl:text-base 2xl:text-lg text-gray-600">
          <p className="mb-4">
            By posting, storing, or transmitting any content on the Website, you
            hereby grant EnjoyAlgorithms a perpetual, worldwide, non-exclusive,
            royalty-free, assignable right and license to use, copy, display,
            perform, create derivative works from, and distribute.
          </p>
          <p className="mb-4">
            EnjoyAlgorithms does not have the ability to control the nature of
            user-generated content offered through the Website. You are solely
            responsible for your interactions with other users of the Website
            and any content you post.
          </p>
          <p className="mb-4">
            EnjoyAlgorithms is not liable for any damage or harm resulting from
            any user-generated content or interactions between users.
            EnjoyAlgorithms reserves the right to remove any content that
            EnjoyAlgorithms deems objectionable, at EnjoyAlgorithms's sole
            discretion.
          </p>
        </div>
        <h2 className="text-xl 2xl:text-2xl tracking-wider mt-6 mb-4">
          Cookies
        </h2>
        <div className="tracking-wider text-sm xl:text-base 2xl:text-lg text-gray-600">
          <p className="mb-4">
            Please refer to the EnjoyAlgorithms cookie policy posted on this
            Website.
          </p>
        </div>

        <h2 className="text-xl 2xl:text-2xl tracking-wider mt-6 mb-4">
          iFrames
        </h2>
        <div className="tracking-wider text-sm xl:text-base 2xl:text-lg text-gray-600">
          <p className="mb-4">
            Without written permission, you must not create frames around our
            Webpages that alter the visual presentation or appearance of our
            Website.
          </p>
        </div>

        <h2 className="text-xl 2xl:text-2xl tracking-wider mt-6 mb-4">
          Your Privacy
        </h2>
        <div className="tracking-wider text-sm xl:text-base 2xl:text-lg text-gray-600">
          <p className="mb-4">
            Please refer to the EnjoyAlgorithms privacy policy posted on this
            Website.
          </p>
        </div>
        <h2 className="text-xl 2xl:text-2xl tracking-wider mt-6 mb-4">
          Disclaimer
        </h2>
        <div className="tracking-wider text-sm xl:text-base 2xl:text-lg text-gray-600">
          <p className="mb-4">
            As long as the information and services on the website are provided
            free of charge, we will not be liable for any loss or damage of any
            nature.
          </p>
        </div>
        <div className="mt-6">
          <Footer />
        </div>
      </div>
    </div>
  )
}
